import Head from 'next/head'
import Image from 'next/image'
import { useState, useEffect } from 'react'

export default function LoginPage({ redirect }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    const checkAuthState = async () => {
      const res = await fetch("/api/auth/auth")
      const data = await res.json()
      if (data.success) {
        setIsLoggedIn(true)
        setTimeout(() => {
          window.location.href = redirect
        }, 1500);
      } else {
        window.location.href = "/api/auth"
      }
    }

    setTimeout(checkAuthState, 2000);
  }, [])

  return (
    <>
      <Head>
        <title>Login - Combat Warriors</title>
        <meta name="description" content="Login to the Combat Warriors website" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        <meta name="theme-color" content="#FF8C00" />
        <meta property="og:title" content="Login - Combat Warriors" />
        <meta property="og:description" content="Login to the Combat Warriors website" />
        <meta property="og:url" content="https://combatwarriors.gg/login" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Combat Warriors" />
        <meta name="twitter:card" content="summary_large_image" />
      </Head>
      
      <main className="flex flex-col items-center justify-center h-screen space-y-4">
        {isLoggedIn ? (
          <>
            <h1 className="text-4xl font-bold text-gray-200">Oops!</h1>
            <p className="text-gray-400">You're already logged in. Please wait while we redirect you to where you came from.</p>
          </>
        ) : (
          <>
            <div className="animate-spin rounded-full h-36 w-36 border-t-2 border-b-2 border-white"></div>
            <h1 className="text-white text-2xl">Redirecting to Discord...</h1>
          </>
        )}
      </main>
    </>
  )
}

export async function getServerSideProps({ req }) {
  const redirect = req?.query?.redirect || req?.query?.r || null

  return {
    props: {
      redirect: redirect && redirect?.startsWith("/") ? redirect : "/"
    }
  }
}